import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import { checkDevice } from "../helpers/windowHelper";
import moment from "moment";
import { userLogoutServices } from "../services/userServices";
import { logoutAction } from "../redux/features/userSlices";
import { LARGEDEVICE } from "../variable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputTextComp from "./form/Input/InputTextComp";
import { checkActiveDate } from "../helpers/accountHelper";
const NavbarComp = ({ enableSearch, placeholder, searchCB, buttonAction }) => {
  const delay = 1;
  let historyPush = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [device, setDevice] = useState("");

  const { user } = useSelector((state) => state);
  const showMenu = (val) => {
    setDisplayMenu(val);
  };

  const handleClickAway = () => {
    setDisplayMenu(false);
  };

  useEffect(() => {
    setDevice(checkDevice());
  }, []);

  const [menu, setMenu] = useState([
    {
      menu: "Catalog",
      link: `${
        user?.customerSetting?.domain
          ? `https://${user.customerSetting.domain}` + "/catalog"
          : `https://${process.env.REACT_APP_DOMAIN_SECOND}/catalog`
      }`,
      isLink: true,
      isNewTab: true,
    },
    {
      menu: "My Catalog",
      link: `${
        user?.user?.domain
          ? `https://${user.customerSetting.domain}` +
            "/catalog/" +
            `${user?.customerSetting?.store_name}`
          : `https://${process.env.REACT_APP_DOMAIN_SECOND}/catalog/${user?.customerSetting?.store_name}`
      }`,
      isLink: true,
      isNewTab: true,
    },
    // {
    //   menu: "Coin",
    //   link: "/coin",
    //   isLink: true,
    //   isNewTab: false,
    // },
    // {
    //   menu: "Pricing",
    //   link: "/pricing",
    //   isLink: true,
    //   isNewTab: false,
    // },
    {
      menu: "Profile",
      link: "/profile",
      isLink: true,
      isNewTab: false,
    },
    {
      menu: "Pengaturan Akun",
      link: "/setting",
      isLink: true,
      isNewTab: false,
    },
    {
      menu: "Tutorial",
      link: "https://www.youtube.com/@idinvitebook",
      isLink: true,
      isNewTab: true,
    },
    {
      menu: "Tutorial Pemasaran",
      link: "https://www.youtube.com/playlist?list=PLVRlgV4n5A19shUh4CiRESQGoLwXD2mJe",
      isLink: true,
      isNewTab: true,
    },
    {
      menu: "Marketing Kit",
      link: "https://drive.google.com/drive/u/0/folders/14jEymGfe-YOcvQkf9REpTyW5n9pWhaya",
      isLink: true,
      isNewTab: true,
    },
    {
      menu: "Logs",
      link: "/logs",
      isLink: true,
      isNewTab: false,
    },
    {
      menu: "Keluar",
      link: "",
      isLink: false,
      isNewTab: false,
    },
  ]);

  const doAction = async (value) => {
    if (value.toLowerCase() === "keluar") {
      dispatch(logoutAction());
      let res = await userLogoutServices();
      if (res) {
        historyPush("/login");
      } else {
        alert("gagal logout");
      }
    }
  };

  const totalDays = () => {
    let y1 = moment(new Date()).year();
    let m1 = moment(new Date()).month();
    let d1 = moment(new Date()).date();
    if (user.app_active) {
      let y2 = moment(user.app_active).year();
      let m2 = moment(user.app_active).month();
      let d2 = moment(user.app_active).date();
      let a = moment([y2, m2, d2]);
      let b = moment([y1, m1, d1]);
      let dif = a.diff(b, "days");
      if (dif < 0) {
        if (user?.app_id !== "63cd0c7254920f655d6052fc") {
          if (window.location.href != "https://app.idinvitebook.com/pricing") {
            window.location.replace("https://app.idinvitebook.com/pricing");
          }
        }
      }
      return dif;
    } else {
      return "-";
    }
  };

  useEffect(() => {
    let timeoutnya = setTimeout(() => {
      if (searchCB) {
        searchCB(search);
      }
    }, delay * 1000);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timeoutnya);
    };
  }, [search]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "100%",
          left: 0,
          top: 0,
          height: "60px",
          background: `linear-gradient(to right, #23ADD3 , #23ADD3)`,
          zIndex: "9999",
          boxShadow: "1px 1px 2px #ccc",
          color: "#fff",
        }}
      >
        <Container>
          <Row>
            <Col md={12}>
              <div className="navbar-warpper">
                <div style={{ minWidth: "280px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Link to="/">
                      <img
                        src="https://d1jcw7e5n1q2pt.cloudfront.net/1701568097543-invblogo.png"
                        width="90px"
                      />
                    </Link>
                    <span style={{ fontSize: "8px", fontWeight: "bold" }}>
                      v1.1.5
                    </span>
                    <div style={{ width: "200px" }}>
                      {/* Masa aktif telah berakhir */}
                      <span style={{ fontSize: "12px", marginLeft: "20px" }}>
                        Masa Aktif :{" "}
                        {checkActiveDate(user?.app_id, user?.app_active) +
                          "hari"}
                      </span>
                    </div>
                  </div>
                </div>
                {enableSearch && searchMode && (
                  <>
                    <div style={{ width: "30px" }}>
                      <div
                        style={{
                          width: "30px",
                          cursor: "pointer",
                          marginTop: "0px",
                        }}
                        onClick={() => {
                          setSearchMode(!searchMode);
                          setSearch("");
                        }}
                      >
                        <div>
                          <FontAwesomeIcon icon={faClose} />
                        </div>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <div style={{ marginTop: "-25px", marginRight: "20px" }}>
                        <InputTextComp
                          placeholder={
                            placeholder ? placeholder : "Ketikkan nama undangan"
                          }
                          value={search}
                          onChangeCB={(value) => setSearch(value)}
                          className="form-control-white"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div
                  style={{
                    width: enableSearch ? "210px" : "170px",
                    marginLeft: "auto",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {device === LARGEDEVICE && enableSearch && !searchMode && (
                      <>
                        <div
                          style={{
                            width: "30px",
                            cursor: "pointer",
                            marginTop: "8px",
                          }}
                          onClick={() => setSearchMode(!searchMode)}
                        >
                          <div>
                            <FontAwesomeIcon icon={faSearch} />
                          </div>
                        </div>
                      </>
                    )}
                    {device === LARGEDEVICE && (
                      <div style={{ flex: 1 }}>
                        <Button
                          variant="primary"
                          className="btn-mobile"
                          onClick={() => {
                            buttonAction();
                          }}
                          style={{ marginTop: "0px" }}
                        >
                          Tambah
                        </Button>
                      </div>
                    )}
                    <div style={{ width: "70px" }}>
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          background: user?.thumbnail
                            ? `url(${user?.thumbnail})`
                            : "#378DD8",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          borderRadius: "100px",
                          marginLeft: "20px",
                          marginTop: "0px",
                          cursor: "pointer",
                          position: "relative",
                          border: "solid 1px #2893D5",
                        }}
                      >
                        <div
                          style={{
                            color: "#fff",
                            fontSize: "24px",
                            fontWeight: "bold",
                            textAlign: "center",
                            width: "40px",
                            height: "40px",
                          }}
                          onClick={() => showMenu(true)}
                        >
                          {user.thumbnail === "" && (
                            <div style={{ position: "relative" }}>
                              {user.name.charAt(0)}
                            </div>
                          )}
                        </div>
                        {displayMenu && (
                          <>
                            <ClickAwayListener onClickAway={handleClickAway}>
                              <div
                                style={{
                                  width: "170px",
                                  position: "absolute",
                                  zIndex: "999",
                                  background: "#fff",
                                  right: 0,
                                  top: "44px",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  color: "#333",
                                  boxShadow:
                                    "0 10px 15px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.23)",
                                }}
                              >
                                <ul style={{ margin: "0px", padding: "0px" }}>
                                  {menu.map((m) => {
                                    return (
                                      <li
                                        style={{
                                          listStyle: "none",
                                          padding: "5px 10px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {m.isLink && (
                                          <a
                                            href={m.link}
                                            target={m.isNewTab ? "_blank" : ""}
                                          >
                                            {m.menu}
                                          </a>
                                        )}
                                        {!m.isLink && (
                                          <span
                                            onClick={() => doAction(m.menu)}
                                          >
                                            Keluar
                                          </span>
                                        )}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </ClickAwayListener>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NavbarComp;
