import axiosInstance from "../helpers/axiosHelper";

export const createProductServices = (data) => {
  return axiosInstance.post(`/api/v1/admin/product`, data);
};

export const updateProductServices = (id, data) => {
  return axiosInstance.put(`/api/v1/admin/product/${id}`, data);
};

export const getProductServices = (pageSize, page, q) => {
  return axiosInstance.get(
    `/api/v1/admin/product?pageSize=${pageSize}&page=${page}${q}`
  );
};

export const deleteProductServices = (id) => {
  return axiosInstance.delete(`/api/v1/admin/product/${id}`);
};
