import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import columns from "./datatable/columns";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../../components/NavbarComp";
import { checkDevice } from "../../helpers/windowHelper";
import { LARGEDEVICE } from "../../variable";
import "react-slideshow-image/dist/styles.css";
import { getReportMitraCoinPerApp } from "../../services/reportServices";
import LoaderComp from "../../components/LoaderComp";
import InputComp from "../../components/form/InputComp";
import { updateCoinServices } from "../../services/coinServices";
const ReportCoinPerApp = () => {
  let { appid } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [dataCoin, setDataCoin] = useState({
    status: "audit",
    coin: "",
    nominal: "",
    note: "",
    app_id: appid,
  });
  let navigate = useNavigate();
  const { user } = useSelector((state) => state);
  useEffect(() => {
    if (!user?.app_id) {
      navigate("/login");
    }
  }, [user]);

  const [loader, setLoader] = useState(true);
  const [profile, setProfile] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [landingpage, setLandingpage] = useState({});
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const handleResize = () => {
    let toolbarHeight = 60;
    setViewportHeight(window.innerHeight - toolbarHeight);
  };

  useEffect(() => {
    // Set initial viewport height
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [device, setDevice] = useState("");

  const [query, setQuery] = useState({
    filterBy: "title",
    type: "",
    status: "",
    tempo: "",
    key: "",
    page: 1,
    pageSize: 25,
  });
  const detailCB = (data, action) => {
    if (action === "goto") {
      navigate(`/invoice/${data?.transaction}`);
    }
  };
  const fetchInvitations = async () => {
    getReportMitraCoinPerApp(query.pageSize, query.page, appid)
      .then((res) => {
        setLandingpage(res.data);
        if (res.data?.coinList.length > 0) {
          setIsEmpty(false);
        }
        setTimeout(() => {
          setLoader(false);
        }, 100);
      })
      .catch((err) => {
        console.log("errsdfsdf", err);
        setIsEmpty(true);
        setLoader(false);
      });
  };

  const getProfile = () => {
    if (user) {
      setProfile(user);
    }
  };

  useEffect(() => {
    getProfile();
    setDevice(checkDevice());
  }, []);

  useEffect(() => {
    fetchInvitations();
  }, [query]);

  const searchCB = (value) => {
    setLoader(true);
    setQuery((prev) => {
      return {
        ...prev,
        key: value,
      };
    });
  };

  const changeDataCoin = (key, value) => {
    setDataCoin((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const submitAudit = async () => {
    let res = await updateCoinServices(dataCoin);
    if (res) {
      alert("berhasil");
    } else {
      alert("gagal");
    }
  };
  return (
    <div>
      <NavbarComp
        enableSearch={false}
        searchCB={searchCB}
        buttonAction={() => setShowModal(true)}
      ></NavbarComp>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Koin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={12}>
                <InputComp
                  type="text"
                  label="status"
                  placeholder="status"
                  value={dataCoin.status}
                  isRequired={true}
                  onChange={(val) => {
                    changeDataCoin("status", val);
                  }}
                ></InputComp>
                <InputComp
                  type="text"
                  label="coin"
                  placeholder="coin"
                  value={dataCoin.coin}
                  isRequired={true}
                  onChange={(val) => {
                    changeDataCoin("coin", val);
                  }}
                ></InputComp>
                <InputComp
                  type="text"
                  label="note"
                  placeholder="note"
                  value={dataCoin.note}
                  isRequired={true}
                  onChange={(val) => {
                    changeDataCoin("note", val);
                  }}
                ></InputComp>
                <InputComp
                  type="text"
                  label="nominal"
                  placeholder="nominal"
                  value={dataCoin.nominal}
                  isRequired={true}
                  onChange={(val) => {
                    changeDataCoin("nominal", val);
                  }}
                ></InputComp>
                <Button
                  className="btn btn-primary btn-full"
                  onClick={() => {
                    submitAudit();
                  }}
                >
                  Update Koin
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Container>
        <Row>
          <Col md={12}>
            {loader ? (
              <div style={{ marginTop: "200px" }}>
                <LoaderComp />
              </div>
            ) : (
              <>
                {!isEmpty && (
                  <>
                    <div className=" pt-1" style={{ background: "#fff" }}>
                      <div
                        style={{
                          height:
                            device === LARGEDEVICE
                              ? "auto"
                              : viewportHeight + "px",
                          marginTop: device === LARGEDEVICE ? "70px" : "0px",
                        }}
                      >
                        <DataTable
                          columns={columns(
                            landingpage,
                            detailCB,
                            query,
                            profile,
                            device
                          )}
                          data={landingpage?.coinList}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={landingpage.total}
                          paginationPerPage={query.pageSize}
                          paginationComponentOptions={{
                            noRowsPerPage: false,
                          }}
                          paginationRowsPerPageOptions={[25, 50, 100, 125, 150]}
                          onChangePage={(page) =>
                            setQuery((prev) => {
                              return {
                                ...prev,
                                page,
                              };
                            })
                          }
                          onChangeRowsPerPage={(pageSize) =>
                            setQuery((prev) => {
                              return {
                                ...prev,
                                page: 1,
                                pageSize,
                              };
                            })
                          }
                        />
                      </div>
                      {device !== LARGEDEVICE && (
                        <div style={{ height: "50px" }}></div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportCoinPerApp;
