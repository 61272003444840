import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import InvitationPage from "./pages/Invitation/InvitationPage";
import WebsiteEditorPage from "./pages/Editor/WebsiteEditorPage";
import RSVPPage from "./pages/RSVP";
import NotFoundPage from "./pages/NotFoundPage";
import ProfilePage from "./pages/ProfilePage";
import SettingPage from "./pages/SettingPage";
import LogsPage from "./pages/LogsPage";
import LoginPage from "./pages/LoginPages";
import LogoutPages from "./pages/LogoutPages";
import UserPage from "./pages/users";
import ReportPage from "./pages/Report";
import ReportCoin from "./pages/ReportCoin";
import ReportCoinPerApp from "./pages/ReportCoinPerApp";
import InvoiceDetail from "./pages/invoice/detail";
import TransactionPage from "./pages/transactions/transactionPage";
import ProductPage from "./pages/Products/ProductPage";
import ProductTransactionPage from "./pages/Products/Transactions/TransactionPage";

const routerProtectedOwner = createBrowserRouter([
  {
    path: "/",
    element: <UserPage></UserPage>,
  },
  {
    path: "/profile",
    element: <ProfilePage></ProfilePage>,
  },
  {
    path: "/invitations",
    element: <InvitationPage></InvitationPage>,
  },
  {
    path: "/report",
    element: <ReportPage></ReportPage>,
  },
  {
    path: "/report-coin",
    element: <ReportCoin></ReportCoin>,
  },
  {
    path: "/report-coin/:appid",
    element: <ReportCoinPerApp></ReportCoinPerApp>,
  },
  {
    path: "/invitations/:appid",
    element: <InvitationPage></InvitationPage>,
  },
  {
    path: "/invoice/:transactionid",
    element: <InvoiceDetail></InvoiceDetail>,
  },
  {
    path: "/transactions/:appid",
    element: <TransactionPage></TransactionPage>,
  },
  {
    path: "/editor-web",
    element: <WebsiteEditorPage></WebsiteEditorPage>,
  },
  {
    path: "/products",
    element: <ProductPage></ProductPage>,
  },
  {
    path: "/products/transaction",
    element: <ProductTransactionPage></ProductTransactionPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/logout",
    element: <LogoutPages></LogoutPages>,
  },
  {
    path: "*",
    element: <NotFoundPage></NotFoundPage>,
  },
]);

export default routerProtectedOwner;
