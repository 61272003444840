import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import columns from "./datatable/columns";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../../components/NavbarComp";
import ContactCSComp from "../../components/ContactCSComp";
import { checkDevice } from "../../helpers/windowHelper";
import { LARGEDEVICE } from "../../variable";
import { getTransactions } from "../../services/transactionServices";
import LoaderComp from "../../components/LoaderComp";
const TransactionPage = () => {
  let navigate = useNavigate();
  let { appid } = useParams();
  const { user } = useSelector((state) => state);
  useEffect(() => {
    if (!user?.app_id) {
      navigate("/login");
    }
  }, [user]);

  const [loader, setLoader] = useState(true);
  const [profile, setProfile] = useState();
  const [isEmpty, setIsEmpty] = useState(false);
  const [transactions, setTransactions] = useState({});
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const handleResize = () => {
    let toolbarHeight = 60;
    setViewportHeight(window.innerHeight - toolbarHeight);
  };

  useEffect(() => {
    // Set initial viewport height
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [device, setDevice] = useState("");

  const [query, setQuery] = useState({
    filterBy: "title",
    type: "",
    status: "",
    tempo: "",
    key: "",
    page: 1,
    pageSize: 25,
  });

  const fetchTransaction = async () => {
    if (user?.role === "63cbe0b21b2e213c687d0b2d") {
      getTransactions(query.pageSize, query.page, appid)
        .then((res) => {
          setTransactions(res.data);
          if (res.data?.transactionList.length > 0) {
            setIsEmpty(false);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch((err) => {
          setIsEmpty(true);
          setLoader(false);
        });
    } else {
      getTransactions(query.pageSize, query.page)
        .then((res) => {
          setTransactions(res.data);
          if (res.data?.transactionList.length > 0) {
            setIsEmpty(false);
          }
          setTimeout(() => {
            setLoader(false);
          }, 100);
        })
        .catch((err) => {
          setIsEmpty(true);
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [query]);

  const getProfile = () => {
    if (user) {
      setProfile(user);
    }
  };

  useEffect(() => {
    getProfile();
    setDevice(checkDevice());
  }, []);

  const searchCB = (value) => {
    setLoader(true);
    setQuery((prev) => {
      return {
        ...prev,
        key: value,
      };
    });
  };

  return (
    <div>
      <NavbarComp enableSearch={true} searchCB={searchCB}></NavbarComp>
      <Container>
        <Row>
          <Col md={12}>
            {isEmpty && query.key?.trim() === "" && (
              <div
                className="text-center pt-4 pb-4 mt-5 mb-5"
                style={{ background: "#fff" }}
              >
                <h4 className="mt-3 mb-2">Oops Belum ada transaksi</h4>
              </div>
            )}

            {loader ? (
              <div style={{ marginTop: "200px" }}>
                <LoaderComp />
              </div>
            ) : (
              <>
                {!isEmpty && (
                  <>
                    <div className=" pt-1" style={{ background: "#fff" }}>
                      <div
                        style={{
                          height:
                            device === LARGEDEVICE
                              ? "auto"
                              : viewportHeight + "px",
                          marginTop: device === LARGEDEVICE ? "70px" : "0px",
                        }}
                      >
                        <DataTable
                          columns={columns(query, device)}
                          data={transactions.transactionList}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={transactions.total}
                          paginationPerPage={query.pageSize}
                          paginationComponentOptions={{
                            noRowsPerPage: false,
                          }}
                          paginationRowsPerPageOptions={[25, 50, 100, 125, 150]}
                          onChangePage={(page) =>
                            setQuery((prev) => {
                              return {
                                ...prev,
                                page,
                              };
                            })
                          }
                          onChangeRowsPerPage={(pageSize) =>
                            setQuery((prev) => {
                              return {
                                ...prev,
                                page: 1,
                                pageSize,
                              };
                            })
                          }
                        />
                      </div>
                      {device !== LARGEDEVICE && (
                        <div style={{ height: "50px" }}></div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
      <ContactCSComp></ContactCSComp>
    </div>
  );
};

export default TransactionPage;
