import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import columns from "./datatable/columns";
import { Modal, Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../../../components/NavbarComp";
import { checkDevice } from "../../../helpers/windowHelper";
import { LARGEDEVICE } from "../../../variable";
import LoaderComp from "../../../components/LoaderComp";
import {
  getAdminTransactions,
  updateResiServices,
} from "../../../services/transactionServices";
import InputComp from "../../../components/form/InputComp";
import Table from "react-bootstrap/Table";
import momentService from "../../../helpers/momentHelper";
const ProductTransactionPage = () => {
  let navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [totalTransaction, setTotalTransaction] = useState();
  const [resi, setResi] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [detailTransaction, setDetailTransaction] = useState();
  useEffect(() => {
    if (!user?.app_id) {
      navigate("/login");
    }
  }, [user]);

  const [loader, setLoader] = useState(true);
  const [transaction, setTransaction] = useState({});
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const handleResize = () => {
    let toolbarHeight = 60;
    setViewportHeight(window.innerHeight - toolbarHeight);
  };

  useEffect(() => {
    // Set initial viewport height
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [device, setDevice] = useState("");

  const [query, setQuery] = useState({
    filterBy: "name",
    type: "",
    status: "",
    tempo: "",
    key: "",
    page: 1,
    pageSize: 25,
    province_id: "",
  });

  const detailCB = (data, action) => {
    switch (action) {
      case "detail":
        setDetailTransaction(data);
        setTimeout(() => {
          setShowModal(false);
          setShowModalDetail(true);
        }, 100);
      case "edit":
        setDetailTransaction(data);
        setTimeout(() => {
          setShowModalDetail(false);
          setShowModal(true);
        }, 100);
        break;
      case "delete":
        break;
    }
  };
  const fetchTransactions = async () => {
    getAdminTransactions(query.pageSize, query.page, "", "shop", "paid")
      .then((res) => {
        setTransaction(res?.data?.transactionList);
        setTotalTransaction(res?.data?.total);
        setTimeout(() => {
          setLoader(false);
        }, 100);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, [query]);

  useEffect(() => {
    setDevice(checkDevice());
  }, []);
  const searchCB = (value) => {
    setLoader(true);
    setQuery((prev) => {
      return {
        ...prev,
        key: value,
      };
    });
  };

  const changeResi = async () => {
    let res = await updateResiServices(detailTransaction?._id, {
      resi: resi,
    });
    if (res) {
      setDetailTransaction({});
      setResi("");
      setShowModal(false);
      fetchTransactions();
    } else {
      alert("gagal");
    }
  };
  return (
    <div>
      <NavbarComp
        enableSearch={true}
        searchCB={searchCB}
        placeholder="Masukkan nama produk"
        buttonAction={() => {}}
      ></NavbarComp>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Resi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "10px 50px" }}>
            <InputComp
              type="text"
              placeholder={"masukkan Resi"}
              label="Masukkan Resi"
              className="form-control-white"
              value={resi}
              onChange={(value) => {
                setResi(value);
              }}
            />
            <Button
              className="btn btn-full btn-primary mt-2"
              onClick={() => changeResi()}
            >
              Simpan
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalDetail}
        onHide={() => {
          setDetailTransaction({});
          setShowModalDetail(false);
        }}
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail Transaksi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "10px 50px" }}>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td>Status Transaksi</td>
                  <td>{detailTransaction?.status}</td>
                </tr>
                <tr>
                  <td>Order Id</td>
                  <td>{detailTransaction?.order_id}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>{momentService(detailTransaction?.updatedAt)}</td>
                </tr>
                <tr>
                  <td>Nama Produk</td>
                  <td>{detailTransaction?.product_name}</td>
                </tr>
                <tr>
                  <td>Harga</td>
                  <td>{detailTransaction?.nominal}</td>
                </tr>
                <tr>
                  <td>Pengirim</td>
                  <td>{detailTransaction?.sender_name}</td>
                </tr>
                <tr>
                  <td>Pengirim (whatsapp)</td>
                  <td>{detailTransaction?.sender_whatsapp}</td>
                </tr>
                <tr>
                  <td>Penerima</td>
                  <td>{detailTransaction?.recipient_name}</td>
                </tr>
                <tr>
                  <td>Penerima (whatsapp)</td>
                  <td>{detailTransaction?.phone_number}</td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>{detailTransaction?.province}</td>
                </tr>
                <tr>
                  <td>Alamat</td>
                  <td>{detailTransaction?.address}</td>
                </tr>
                <tr>
                  <td>Toko</td>
                  <td>
                    <a href={`${detailTransaction?.shoplink}`} target="_blank">
                      {" "}
                      <i
                        className="fas fa-link mr-3"
                        style={{ fontSize: 18 }}
                      ></i>{" "}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Resi</td>
                  <td>{detailTransaction?.resi}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
      <Container>
        <>
          <div className=" pt-1" style={{ background: "#fff" }}>
            <div
              style={{
                height: device === LARGEDEVICE ? "auto" : viewportHeight + "px",
                marginTop: device === LARGEDEVICE ? "70px" : "0px",
              }}
            >
              {loader && <LoaderComp></LoaderComp>}
              {!loader && (
                <DataTable
                  columns={columns(transaction, detailCB, query, device)}
                  data={transaction}
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationTotalRows={totalTransaction}
                  paginationPerPage={query.pageSize}
                  paginationComponentOptions={{
                    noRowsPerPage: false,
                  }}
                  paginationRowsPerPageOptions={[25, 50, 100, 125, 150]}
                  onChangePage={(page) =>
                    setQuery((prev) => {
                      return {
                        ...prev,
                        page,
                      };
                    })
                  }
                  onChangeRowsPerPage={(pageSize) =>
                    setQuery((prev) => {
                      return {
                        ...prev,
                        page: 1,
                        pageSize,
                      };
                    })
                  }
                />
              )}
            </div>
            {device !== LARGEDEVICE && <div style={{ height: "50px" }}></div>}
          </div>
        </>
      </Container>
    </div>
  );
};

export default ProductTransactionPage;
