import React from "react";
import { SMARTPHONE } from "../../../../variable";
import momentService from "../../../../helpers/momentHelper";

const columns = (row, detail, query, device) => {
  return [
    {
      name: "",
      cell: (row, index) => (query.page - 1) * query.pageSize + index + 1,
      width: device === SMARTPHONE ? "10%" : "60px",
    },
    {
      name: "status",
      cell: (row) => row.status,
      width: "150px",
    },
    {
      name: "product_name",
      cell: (row) => row?.product_name,
      width: "150px",
    },
    {
      name: "Harga",
      cell: (row) => row.nominal,
      width: "150px",
    },
    {
      name: "resi",
      cell: (row) => row.resi,
      width: "150px",
    },
    {
      name: "Tanggal",
      cell: (row) => momentService(row.updatedAt),
      width: "150px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <ul
            style={{
              listStyle: "none",
              display: "inline",
              margin: "0px",
              padding: "0px",
            }}
          >
            <li
              style={{ display: "inline" }}
              onClick={() => detail(row, "detail")}
            >
              {" "}
              <i
                className="fas fa-eye mr-3 "
                style={{ fontSize: 18, color: "#2E3159" }}
              ></i>{" "}
            </li>
            <li style={{ display: "inline" }}>
              {" "}
              <a href={`${row?.shoplink}`} target="_blank">
                {" "}
                <i
                  className="fas fa-link mr-3"
                  style={{ fontSize: 18 }}
                ></i>{" "}
              </a>
            </li>
            <li
              style={{ display: "inline" }}
              onClick={() => detail(row, "edit")}
            >
              {" "}
              <i
                className="fas fa-pencil mr-3 "
                style={{ fontSize: 18, color: "#2E3159" }}
              ></i>{" "}
            </li>
          </ul>
        </>
      ),
      width: "15%",
    },
  ];
};

export default columns;
