import axiosInstance from "../helpers/axiosHelper";
const path = "/api/v1/customer/transaction";
const pathadmin = "/api/v1/admin/transaction";

export const getTransactions = (pageSize, page, appid, type) => {
  let url = `${path}?page=${page}&pageSize=${pageSize}&type=${type}`;
  if (appid) {
    url = `${pathadmin}?page=${page}&pageSize=${pageSize}&type=${type}&app_id=${appid}`;
  }
  return axiosInstance.get(url);
};

export const getAdminTransactions = (pageSize, page, appid, type, status) => {
  let url = `${pathadmin}?page=${page}&pageSize=${pageSize}&type=${type}&status=${status}`;
  return axiosInstance.get(url);
};
export const getTransactionDetail = (role, id) => {
  return axiosInstance.get(`/api/v1/${role}/transaction/${id}`);
};
export const updateTransaction = (data) => {
  return axiosInstance.put(`${path}`, data);
};
export const updateResiServices = (id, data) => {
  return axiosInstance.put(`${pathadmin}/${id}/resi`, data);
};
