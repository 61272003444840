import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import columns from "./datatable/columns";
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../../components/NavbarComp";
import { checkDevice } from "../../helpers/windowHelper";
import { LARGEDEVICE } from "../../variable";
import LoaderComp from "../../components/LoaderComp";
import { getProvinceServices } from "../../services/provinceServices";
import InputComp from "../../components/form/InputComp";
import {
  createProductServices,
  deleteProductServices,
  getProductServices,
  updateProductServices,
} from "../../services/productServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
const ProductPage = () => {
  let navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [listProvince, setListProvince] = useState([]);
  const [totalProduct, setTotalProduct] = useState();
  const [dataProduct, setDataProduct] = useState({
    _id: "",
    name: "",
    desc: "",
    images: [""],
    price: "",
    weight: "",
    shoplink: "",
    province_id: "67a443fb50dbee196ea02266",
  });
  const clearDataProduct = () => {
    setDataProduct({
      _id: "",
      name: "",
      desc: "",
      images: [""],
      price: "",
      weight: "",
      shoplink: "",
      province_id: "67a443fb50dbee196ea02266",
    });
  };
  useEffect(() => {
    if (!user?.app_id) {
      navigate("/login");
    }
  }, [user]);

  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [products, setProducts] = useState({});
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const handleResize = () => {
    let toolbarHeight = 60;
    setViewportHeight(window.innerHeight - toolbarHeight);
  };

  useEffect(() => {
    // Set initial viewport height
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [device, setDevice] = useState("");

  const [query, setQuery] = useState({
    filterBy: "name",
    type: "",
    status: "",
    tempo: "",
    key: "",
    page: 1,
    pageSize: 25,
    province_id: "",
  });

  const detailCB = (data, action) => {
    switch (action) {
      case "edit":
        setDataProduct(data);
        setTimeout(() => {
          setShowModal(true);
        }, 100);
        break;
      case "delete":
        setDataProduct(data);
        setShowModal(false);
        setTimeout(() => {
          setShowModalDelete(true);
        }, 100);
        break;
    }
  };
  const fetchProducts = async () => {
    var q = `&filterBy=${query?.filterBy}&keyword=${query?.key}&province_id=${query?.province_id}`;
    getProductServices(query.pageSize, query.page, q)
      .then((res) => {
        console.log("prod", res);
        setProducts(res?.data?.result);
        setTotalProduct(res?.data?.total[0]?.totalCount);
        setTimeout(() => {
          setLoader(false);
        }, 100);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, [query]);

  useEffect(() => {
    setDevice(checkDevice());
  }, []);
  const searchCB = (value) => {
    setLoader(true);
    setQuery((prev) => {
      return {
        ...prev,
        key: value,
      };
    });
  };
  const fetchProvince = async () => {
    let res = await getProvinceServices();
    if (res) {
      setListProvince(res?.data?.listProvinces);
    }
  };
  useEffect(() => {
    fetchProvince();
  }, []);

  const changeProduct = (key, value, index) => {
    if (key === "images") {
      let tempDataProduct = { ...dataProduct };
      tempDataProduct.images[index] = value;
      setDataProduct(tempDataProduct);
    } else {
      setDataProduct((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    }
  };

  const savingProduct = async () => {
    if (dataProduct?._id) {
      let res = await updateProductServices(dataProduct?._id, dataProduct);
      fetchProducts();
      setShowModal(false);
    } else {
      let res = await createProductServices(dataProduct);
      fetchProducts();
      setShowModal(false);
    }
    clearDataProduct();
  };
  const deleteProduct = async (id) => {
    let res = await deleteProductServices(id);
    if (res) {
      setShowModalDelete(false);
      clearDataProduct();
      fetchProducts();
    }
  };

  const openProductModal = () => {
    setShowModalDelete(false);
    setShowModal(true);
    clearDataProduct();
  };

  const removeImage = (index) => {
    let temp = { ...dataProduct };
    temp.images.splice(index, 1);
    setDataProduct(temp);
  };
  const addImage = () => {
    let temp = { ...dataProduct };
    temp.images.push("");
    setDataProduct(temp);
  };
  return (
    <div>
      <Modal
        show={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        style={{ marginTop: "100px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Hapus Produk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center" style={{ padding: "60px 50px" }}>
            <h3>Yakin Hapus Produk {dataProduct.name}?</h3>
            <Button
              className="ml-3 mr-3 mt-4"
              onClick={() => setShowModalDelete(false)}
            >
              Batal
            </Button>
            <Button
              className="ml-3 mr-3 mt-4"
              onClick={() => deleteProduct(dataProduct?._id)}
            >
              Hapus
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {dataProduct?._id ? "Kelola Produk" : "Tambah Produk"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <Col md={12}>
                <InputComp
                  type="text"
                  autofocus={true}
                  label="Nama produk"
                  className="form-control-white"
                  placeholder="masukkan nama produk"
                  value={dataProduct?.name}
                  onChange={(value) => {
                    changeProduct("name", value);
                  }}
                />
                <InputComp
                  type="text"
                  label="Deskripsi"
                  autofocus={true}
                  className="form-control-white"
                  placeholder="masukkan deskripsi produk"
                  value={dataProduct?.desc}
                  onChange={(value) => {
                    changeProduct("desc", value);
                  }}
                />
                <InputComp
                  type="text"
                  label="Berat"
                  autofocus={true}
                  className="form-control-white"
                  placeholder="masukkan berat produk"
                  value={dataProduct?.weight}
                  onChange={(value) => {
                    changeProduct("weight", value);
                  }}
                />
                {dataProduct?.images?.map((image, index) => {
                  return (
                    <>
                      <InputComp
                        type="text"
                        label="Link Gambar"
                        className="form-control-white"
                        placeholder="masukkan Link gambar"
                        autofocus={true}
                        value={image}
                        onChange={(value) => {
                          changeProduct("images", value, index);
                        }}
                      />
                      <div onClick={() => removeImage(index)}>remove</div>
                    </>
                  );
                })}
                <div
                  style={{ padding: "10px", cursor: "pointer" }}
                  onClick={() => addImage()}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <InputComp
                  type="number"
                  label="Harga"
                  autofocus={true}
                  className="form-control-white"
                  placeholder="masukkan harga produk"
                  value={dataProduct?.price}
                  onChange={(value) => {
                    changeProduct("price", value);
                  }}
                />
                <InputComp
                  type="text"
                  label="Link Toko"
                  autofocus={true}
                  className="form-control-white"
                  placeholder="masukkan link toko"
                  value={dataProduct?.shoplink}
                  onChange={(value) => {
                    changeProduct("shoplink", value);
                  }}
                />

                <Form.Group className="mb-1">
                  <Form.Label>Provinsi</Form.Label>
                  <Form.Select
                    value={dataProduct?.province_id}
                    onChange={($e) => {
                      changeProduct("province_id", $e.target.value);
                    }}
                  >
                    {listProvince?.sort().map((province) => {
                      return (
                        <option
                          value={province?._id}
                          selected={province?._id == dataProduct?.province_id}
                        >
                          {province?.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                <Button
                  className="btn btn-primary btn-full mt-2"
                  onClick={() => {
                    savingProduct();
                  }}
                >
                  {dataProduct?._id ? "Ubah" : "Tambah"}
                </Button>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <NavbarComp
        enableSearch={true}
        searchCB={searchCB}
        placeholder="Masukkan nama produk"
        buttonAction={() => openProductModal()}
      ></NavbarComp>
      <Container>
        <>
          <div className=" pt-1" style={{ background: "#fff" }}>
            <div
              style={{
                height: device === LARGEDEVICE ? "auto" : viewportHeight + "px",
                marginTop: device === LARGEDEVICE ? "70px" : "0px",
              }}
            >
              {loader && <LoaderComp></LoaderComp>}
              {!loader && (
                <DataTable
                  columns={columns(products, detailCB, query, device)}
                  data={products}
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationTotalRows={totalProduct}
                  paginationPerPage={query.pageSize}
                  paginationComponentOptions={{
                    noRowsPerPage: false,
                  }}
                  paginationRowsPerPageOptions={[25, 50, 100, 125, 150]}
                  onChangePage={(page) =>
                    setQuery((prev) => {
                      return {
                        ...prev,
                        page,
                      };
                    })
                  }
                  onChangeRowsPerPage={(pageSize) =>
                    setQuery((prev) => {
                      return {
                        ...prev,
                        page: 1,
                        pageSize,
                      };
                    })
                  }
                />
              )}
            </div>
            {device !== LARGEDEVICE && <div style={{ height: "50px" }}></div>}
          </div>
        </>
      </Container>
    </div>
  );
};

export default ProductPage;
