import React from "react";
import { SMARTPHONE } from "../../../variable";

const columns = (row, detail, query, device) => {
  const dekstop = [
    {
      name: "",
      cell: (row, index) => (query.page - 1) * query.pageSize + index + 1,
      width: device === SMARTPHONE ? "10%" : "60px",
    },
    {
      name: "Nama",
      cell: (row) => (
        <>
          <div style={{ display: "flex" }}>
            <div style={{ width: "110px" }}>
              <img
                src={
                  row?.images
                    ? row?.images[0]
                    : "https://d1jcw7e5n1q2pt.cloudfront.net/1701822331883-notfound.jpg"
                }
                style={{
                  borderRadius: "20px",
                  padding: "10px",
                  width: "100px",
                }}
                className="img-responsive"
              />
            </div>
          </div>
        </>
      ),
      width: "150px",
    },
    {
      name: "Nama",
      cell: (row) => row?.name,
      width: "150px",
    },
    {
      name: "Harga",
      cell: (row) => row?.price,
      width: "150px",
    },
    {
      name: "Berat",
      cell: (row) => row?.weight,
      width: "150px",
    },
    {
      name: "Provinsi",
      cell: (row) => row?.provinceDetails?.name,
      width: "150px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <ul
            style={{
              listStyle: "none",
              display: "inline",
              margin: "0px",
              padding: "0px",
            }}
          >
            <li style={{ display: "inline" }}>
              {" "}
              <a href={`${row?.shoplink}`} target="_blank">
                {" "}
                <i
                  className="fas fa-eye mr-3"
                  style={{ fontSize: 18 }}
                ></i>{" "}
              </a>
            </li>
            <li
              style={{ display: "inline" }}
              onClick={() => detail(row, "edit")}
            >
              {" "}
              <i
                className="fas fa-pencil mr-3 "
                style={{ fontSize: 18, color: "#2E3159" }}
              ></i>{" "}
            </li>
            <li
              style={{ display: "inline" }}
              onClick={() => detail(row, "delete")}
            >
              <i className="fas fa-trash mr-3" style={{ fontSize: 18 }}></i>{" "}
            </li>
          </ul>
        </>
      ),
      width: "15%",
    },
  ];

  return dekstop;
};

export default columns;
